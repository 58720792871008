var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import ShareBanner from '../components/share-and-win/ShareBanner.vue';
import ShareLink from '../components/share-and-win/ShareLink.vue';
import ShareHistory from '../components/share-and-win/ShareHistory.vue';
import ShareResults from '../components/share-and-win/ShareResults.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
import LoadingDots from '@/components/LoadingDots.vue';
let Shareandwin = class Shareandwin extends Vue {
    constructor() {
        super(...arguments);
        this.referralEndpoint = {};
        this.referralLink = 'speedio.com.br/invite/';
        this.loading = false;
    }
    async mounted() {
        // TO DO - Melhorar a chamada do endpoint pra ser carregado no mounted do config
        this.loading = true;
        //@ts-ignore
        const list = await this.actions.getReferralList();
        if (list && list.data) {
            this.referralEndpoint = list.data;
        }
        //@ts-ignore
        const link = await this.actions.getReferralLink();
        this.referralLink += link.data.referral_id;
        this.loading = false;
        mixpanelTracking('mgm: Acessou painel member-get-member');
    }
};
Shareandwin = __decorate([
    Component(connect(({ actions }) => ({ actions: actions.users }), {
        components: {
            ShareBanner,
            ShareLink,
            ShareResults,
            ShareHistory,
            LoadingDots,
        },
    }))
], Shareandwin);
export default Shareandwin;
