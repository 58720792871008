var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
let ShareBanner = class ShareBanner extends Vue {
    get maxCredits() {
        const creditsClicks = this.result.click_credits_by_indication || 0;
        const creditsExport = this.result.export_credits_by_indication || 0;
        const maxUsers = this.result.max_registers_indication || 0;
        return (creditsClicks + creditsExport) * maxUsers;
    }
};
__decorate([
    Prop({ default: {} })
], ShareBanner.prototype, "result", void 0);
ShareBanner = __decorate([
    Component({})
], ShareBanner);
export default ShareBanner;
