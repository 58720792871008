var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDateToBR } from '@/shared/utils/helpers';
let ShareHistory = class ShareHistory extends Vue {
    getCredits(index, creditType, creditUse) {
        const credits = this.history[index].credits;
        const getCreditType = credits.filter(el => el.type == creditType);
        const total = getCreditType.reduce((sum, item) => sum + item.amount, 0) || 0;
        if (creditUse == 'total')
            return total;
        else
            return (total - getCreditType.reduce((sum, item) => sum + item.remaining, 0) ||
                0);
    }
    formatDate(date) {
        return formatDateToBR(date.toString());
    }
};
__decorate([
    Prop({ default: {} })
], ShareHistory.prototype, "history", void 0);
ShareHistory = __decorate([
    Component({})
], ShareHistory);
export default ShareHistory;
