var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
let ShareResults = class ShareResults extends Vue {
    get indicationRegisters() {
        return `${this.result.registers}/${this.result.max_registers_indication}`;
    }
    get totalIndicationCredits() {
        const hasCredits = this.result && !isEmpty(this.result.credits);
        if (hasCredits) {
            const getCredits = this.result.credits.reduce((sum, item) => sum + item.credits.reduce((a, b) => a + b.amount, 0), 0);
            const maxCredits = (this.result.click_credits_by_indication +
                this.result.export_credits_by_indication) *
                this.result.max_registers_indication;
            return `${getCredits}/${maxCredits}`;
        }
        else {
            return 0;
        }
    }
    indicationCredits(type) {
        const hasCredits = this.result && !isEmpty(this.result.credits);
        if (hasCredits) {
            const total = this.result.credits.reduce((a, b) => a +
                b.credits
                    .filter(el => el.type == type)
                    .reduce((c, b) => c + b.amount, 0), 0);
            const consumed = total -
                this.result.credits.reduce((a, b) => a +
                    b.credits
                        .filter(el => el.type == type)
                        .reduce((c, b) => c + b.remaining, 0), 0);
            return `${consumed}/${total}`;
        }
        else {
            return 0;
        }
    }
};
__decorate([
    Prop({ default: {} })
], ShareResults.prototype, "result", void 0);
ShareResults = __decorate([
    Component({})
], ShareResults);
export default ShareResults;
