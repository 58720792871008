var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import IconLinkedin from '@/components/Icons/SocialMedia/IconLinkedin.vue';
import IconFacebook from '@/components/Icons/SocialMedia/IconFacebook.vue';
import IconTwitter from '@/components/Icons/SocialMedia/IconTwitter.vue';
import IconWhatsApp from '@/components/Icons/SocialMedia/IconWhatsApp.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let ShareLink = class ShareLink extends Vue {
    constructor() {
        super(...arguments);
        this.copiedLink = false;
        this.socialMediaLinks = {
            IconLinkedin: {
                url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
            },
            IconFacebook: {
                url: 'https://www.facebook.com/sharer/sharer.php?u=',
            },
            IconTwitter: {
                url: 'https://twitter.com/intent/tweet?url=',
                iconSize: '18px',
            },
            IconWhatsApp: {
                url: 'https://api.whatsapp.com/send/?text=',
                iconSize: '18px',
            },
        };
    }
    shareWithSocialMedia(url) {
        window.open(`${url}${this.shareLink}`, '_blank');
        mixpanelTracking('mgm: Compartilhou nas redes sociais');
    }
    copyLink() {
        if (this.copiedLink)
            return;
        this.copiedLink = true;
        navigator.clipboard.writeText(this.shareLink);
        setTimeout(() => {
            this.copiedLink = false;
        }, 1000);
        mixpanelTracking('mgm: Copiou link');
    }
};
__decorate([
    Prop({ default: '' })
], ShareLink.prototype, "shareLink", void 0);
ShareLink = __decorate([
    Component({
        components: {
            IconLinkedin,
            IconFacebook,
            IconTwitter,
            IconWhatsApp,
        },
    })
], ShareLink);
export default ShareLink;
